var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"my-2",attrs:{"no-gutters":_vm.$vuetify.breakpoint.xsOnly,"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Position","rules":"required|min:2|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.position'),"dense":"","outlined":""},model:{value:(_vm.siteData.senior_staff_position),callback:function ($$v) {_vm.$set(_vm.siteData, "senior_staff_position", $$v)},expression:"siteData.senior_staff_position"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Staff Name","rules":"required|min:2|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.name'),"dense":"","outlined":""},model:{value:(_vm.siteData.senior_staff_name),callback:function ($$v) {_vm.$set(_vm.siteData, "senior_staff_name", $$v)},expression:"siteData.senior_staff_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Staff Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.email'),"dense":"","outlined":""},model:{value:(_vm.siteData.senior_staff_email),callback:function ($$v) {_vm.$set(_vm.siteData, "senior_staff_email", $$v)},expression:"siteData.senior_staff_email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Staff Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.phoneNumber'),"dense":"","outlined":""},model:{value:(_vm.siteData.senior_staff_phone_number),callback:function ($$v) {_vm.$set(_vm.siteData, "senior_staff_phone_number", $$v)},expression:"siteData.senior_staff_phone_number"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }